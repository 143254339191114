import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import ogImage from "../images/Frame-7.png"

const SolutionPage = () => {
  const imageData = useStaticQuery(graphql`
    query ServiceImages {
      banner: file(relativePath: { eq: "Services_Banner.png" }) {
        id
        childImageSharp {
          fluid(maxWidth: 1600, quality: 85) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      service_1: file(relativePath: { eq: "services-3.jpg" }) {
        id
        childImageSharp {
          fluid(maxWidth: 1600, quality: 85) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      service_2: file(relativePath: { eq: "services-2.jpg" }) {
        id
        childImageSharp {
          fluid(maxWidth: 1600, quality: 85) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      service_3: file(relativePath: { eq: "services-1.jpg" }) {
        id
        childImageSharp {
          fluid(maxWidth: 1600, quality: 85) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <SEO
        title="Services | Ecommerce Website Development, Design & Growth"
        description="Explore cutting-edge ecommerce website development. Design immersive and experiential Shopify, Magento and custom stores that captivate customers."
        image={`https://www.coderapper.com${ogImage}`}
      />
      <div className="solutions hero">
        <div className="container-lg">
          <div className="row">
            <div className="col-md-9">
              <div className="page-banner extra-gutter left">
                <h1
                  data-sal="slide-up"
                  data-sal-delay="100"
                  data-sal-easing="ease"
                  data-sal-duration="800"
                  className=" text text-h1 text-title-font text-fw-regular text-black2 gap-1x"
                >
                  We make e-commerce easy.
                </h1>
                <p
                  data-sal="slide-up"
                  data-sal-delay="250"
                  data-sal-easing="ease"
                  data-sal-duration="800"
                  className=" text text-p-md text-black4 text-fw-regular gap-1x extra-gutter right"
                >
                  We’re growth consultants, tech partners, madcap designers and
                  ROI-driven commerce specialists. Our robust processes, crafted
                  over years of experimentation, will make you feel that we’re
                  in the same room, and on the same page.
                </p>
                <p
                  data-sal="slide-up"
                  data-sal-delay="400"
                  data-sal-easing="ease"
                  data-sal-duration="800"
                  className=" text text-p-md text-black4 text-fw-regular gap-2x"
                >
                  How can we help you today?
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="solution-banner">
        <Img fluid={imageData.banner.childImageSharp.fluid} alt="banner" />
      </div>
      <div className="solution wrapper">
        <div className="solution-parent">
          <div className="container-lg">
            <div className="row align-items-center flex-column-reverse flex-md-row extra-gutter left right">
              <div className="col col-md-7 ">
                <div className="content extra-gutter right">
                  <h3
                    data-sal="slide-up"
                    data-sal-delay="100"
                    data-sal-easing="ease"
                    data-sal-duration="800"
                    className="text text-h3 text-title-font text-white1 text-fw-light gap-2x"
                  >
                    Design intuitive interfaces that delight and deliver.
                  </h3>
                  <p
                    data-sal="slide-up"
                    data-sal-delay="200"
                    data-sal-easing="ease"
                    data-sal-duration="800"
                    className="text text-p-md text-white1 text-fw-light gap-1x"
                  >
                    We craft highly immersive and aesthetic customer experiences
                    that educate, inspire and engage.
                  </p>
                  <ul
                    data-sal="slide-up"
                    data-sal-delay="400"
                    data-sal-easing="ease"
                    data-sal-duration="800"
                    className="text text-p-md text-white1 text-fw-light gap-1x"
                  >
                    <li>User Experience Audit</li>
                    <li>User Experience Planning</li>
                    <li>User Interface & Visual Design </li>
                    <li>Product Design & Prototyping</li>
                    <li>Landing Page Design</li>
                    <li>Communication & Visual Design</li>
                  </ul>
                </div>
              </div>
              <div className="col col-md-5">
                <div
                  data-sal="slide-left"
                  data-sal-delay="200"
                  data-sal-easing="ease"
                  data-sal-duration="800"
                  className="featured-image"
                >
                  <Img
                    fluid={imageData.service_1.childImageSharp.fluid}
                    alt="design"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="solution-parent">
          <div className="container-lg">
            <div className="row align-items-center extra-gutter left">
              <div className="col-12 col-md-5">
                <div
                  data-sal="slide-right"
                  data-sal-delay="200"
                  data-sal-easing="ease"
                  data-sal-duration="800"
                  className="featured-image extra-padding"
                >
                  <Img
                    fluid={imageData.service_2.childImageSharp.fluid}
                    alt="development"
                  />
                </div>
              </div>
              <div className="col-12 col-md-7">
                <div className="content extra-gutter left">
                  <h3
                    data-sal="slide-up"
                    data-sal-delay="100"
                    data-sal-easing="ease"
                    data-sal-duration="800"
                    className="text text-h3 text-title-font text-white1 text-fw-light gap-2x"
                  >
                    Build future-proof and functional e-commerce stores.
                  </h3>
                  <p
                    data-sal="slide-up"
                    data-sal-delay="200"
                    data-sal-easing="ease"
                    data-sal-duration="800"
                    className="text text-p-md text-white1 text-fw-light gap-1x"
                  >
                    Accelerate your GTM plan with custom designed and scalable
                    stores, built for the future.
                  </p>
                  <ul
                    data-sal="slide-up"
                    data-sal-delay="400"
                    data-sal-easing="ease"
                    data-sal-duration="800"
                    className="text text-p-md text-white1 text-fw-light gap-1x"
                  >
                    <li>E-commerce Platform Evaluation</li>
                    <li>Custom Theme Development</li>
                    <li>E-commerce Store Development</li>
                    <li>E-commerce App Development</li>
                    <li>Platform</li>
                    <li>ERP & Data Migration</li>
                    <li>Site Performance Audit</li>
                    <li>ADA Compliance</li>
                    <li>Site Speed Improvement</li>
                    <li>Cloud Hosting & Maintenance</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="solution-parent">
          <div className="container-lg">
            <div className="row align-items-center flex-column-reverse flex-md-row extra-gutter left right">
              <div className="col col-md-7 ">
                <div className="content extra-gutter right">
                  <h3
                    data-sal="slide-up"
                    data-sal-delay="100"
                    data-sal-easing="ease"
                    data-sal-duration="800"
                    className="text text-h3 text-title-font text-white1 text-fw-light gap-2x"
                  >
                    Optimize, automate and improve your bottomline.
                  </h3>
                  <p
                    data-sal="slide-up"
                    data-sal-delay="200"
                    data-sal-easing="ease"
                    data-sal-duration="800"
                    className="text text-p-md text-white1 text-fw-light gap-1x"
                  >
                    Hit the ground running with a long term growth plan for
                    improved revenues, AOV and conversion.
                  </p>
                  <ul
                    data-sal="slide-up"
                    data-sal-delay="400"
                    data-sal-easing="ease"
                    data-sal-duration="800"
                    className="text text-p-md text-white1 text-fw-light gap-1x"
                  >
                    <li>Growth Marketing Strategy</li>
                    <li>E-commerce GTM Strategy</li>
                    <li>Email Marketing Strategy</li>
                    <li>Marketing Automation</li>
                    <li>Conversion Rate Optimization (Funnel Audit)</li>
                    <li>A/B Testing & Optimization</li>
                    <li>Facebook, Instagram & Google Shopping Setup</li>
                    <li>Ad Campaign Management (PPC, SEM & Social)</li>
                    <li>Social Media Marketing</li>
                  </ul>
                </div>
              </div>
              <div className="col col-md-5">
                <div
                  data-sal="slide-left"
                  data-sal-delay="200"
                  data-sal-easing="ease"
                  data-sal-duration="800"
                  className="featured-image"
                >
                  <Img
                    fluid={imageData.service_3.childImageSharp.fluid}
                    alt="optimize"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="work-with-us">
          <div className="container-lg">
            <div className="row extra-gutter left right">
              <div className="col-md-12">
                <div className="section-tag white">
                  <span>Why Work With Us</span>
                </div>
                <h2
                  data-sal="slide-up"
                  data-sal-delay="100"
                  data-sal-easing="ease"
                  data-sal-duration="800"
                  className="text text-h1 text-title-font text-fw-light text-white gap-2x"
                >
                  We’re partners for pivotal growth.
                </h2>
                <p
                  data-sal="slide-up"
                  data-sal-delay="200"
                  data-sal-easing="ease"
                  data-sal-duration="800"
                  className="text text-p-md text-white1 text-fw-light gap-3x"
                >
                  Here’s why you should work with us.
                </p>
              </div>
            </div>
            <div className="extra-gutter left right">
              <div className="work-flex-row ">
                <div className="work-card">
                  <h4
                    data-sal="slide-up"
                    data-sal-delay="400"
                    data-sal-easing="ease"
                    data-sal-duration="800"
                    className="text text-h4 text-title-font text-fw-light text-white gap-1x"
                  >
                    We’re specialists.
                  </h4>
                  <p
                    data-sal="slide-up"
                    data-sal-delay="500"
                    data-sal-easing="ease"
                    data-sal-duration="800"
                    className="text text-p-md text-white1 text-fw-light gap-2x"
                  >
                    We do a lot of things well, but three things incredibly well
                    - building brands, driving conversions and making money.
                    When you’re in e-commerce, specialization matters.
                  </p>
                </div>
                <div className="work-card">
                  <h4
                    data-sal="slide-up"
                    data-sal-delay="400"
                    data-sal-easing="ease"
                    data-sal-duration="800"
                    className="text text-h4 text-title-font text-fw-light text-white gap-1x"
                  >
                    We’re data-driven.
                  </h4>
                  <p
                    data-sal="slide-up"
                    data-sal-delay="500"
                    data-sal-easing="ease"
                    data-sal-duration="800"
                    className="text text-p-md text-white1 text-fw-light gap-2x"
                  >
                    Our processes are driven by the right numbers. We optimize
                    for conversions from planning to design and development,
                    with special attention to lifetime value.
                  </p>
                </div>
                <div className="work-card">
                  <h4
                    data-sal="slide-up"
                    data-sal-delay="400"
                    data-sal-easing="ease"
                    data-sal-duration="800"
                    className="text text-h4 text-title-font text-fw-light text-white gap-1x"
                  >
                    We’re transparent.
                  </h4>
                  <p
                    data-sal="slide-up"
                    data-sal-delay="500"
                    data-sal-easing="ease"
                    data-sal-duration="800"
                    className="text text-p-md text-white1 text-fw-light gap-2x"
                  >
                    We thrive in collaboration and prefer long term client
                    relationships. Our transparent processes come with accurate
                    time and cost reports and clear communication.
                  </p>
                </div>
              </div>
              <span
                data-sal="slide-up"
                data-sal-delay="600"
                data-sal-easing="ease"
                data-sal-duration="800"
                className="redirect-text"
              >
                Here’s more about us.
              </span>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default SolutionPage
